// Generated by Framer (f318921)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HqYmyavbW", "aSfk_vrpH"];

const serializationHash = "framer-1hoto"

const variantClassNames = {aSfk_vrpH: "framer-v-lbt4j9", HqYmyavbW: "framer-v-1a6lt9g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "HqYmyavbW", "Variant 2": "aSfk_vrpH"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "HqYmyavbW"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HqYmyavbW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 168, intrinsicWidth: 206, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 336, pixelWidth: 412, src: "https://framerusercontent.com/images/2eruU3rJfeA1ADrQErq1kce0Go.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1a6lt9g", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HqYmyavbW"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({aSfk_vrpH: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBCb2xk", "--framer-font-family": "\"Brandon Text Bold\", \"Brandon Text Bold Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.16em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64)))"}}>Talent movements</motion.p></React.Fragment>} className={"framer-20hyhh"} fonts={["CUSTOM;Brandon Text Bold"]} layoutDependency={layoutDependency} layoutId={"LXTGbagx5"} style={{"--extracted-r6o4lv": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1hoto.framer-1f9c9hk, .framer-1hoto .framer-1f9c9hk { display: block; }", ".framer-1hoto.framer-1a6lt9g { height: 168px; overflow: visible; position: relative; width: 206px; }", ".framer-1hoto .framer-20hyhh { bottom: 50px; flex: none; height: auto; left: -138px; position: absolute; white-space: pre-wrap; width: 110px; word-break: break-word; word-wrap: break-word; }", ".framer-1hoto.framer-v-lbt4j9.framer-1a6lt9g { aspect-ratio: 1.2261904761904763 / 1; height: var(--framer-aspect-ratio-supported, 168px); }", ".framer-1hoto.framer-v-lbt4j9 .framer-20hyhh { bottom: -22px; left: -97px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 168
 * @framerIntrinsicWidth 206
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"aSfk_vrpH":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOZW9WCzbs: React.ComponentType<Props> = withCSS(Component, css, "framer-1hoto") as typeof Component;
export default FramerOZW9WCzbs;

FramerOZW9WCzbs.displayName = "big 8 blue 2";

FramerOZW9WCzbs.defaultProps = {height: 168, width: 206};

addPropertyControls(FramerOZW9WCzbs, {variant: {options: ["HqYmyavbW", "aSfk_vrpH"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerOZW9WCzbs, [{explicitInter: true, fonts: [{family: "Brandon Text Bold", source: "custom", url: "https://framerusercontent.com/assets/O5k6Mj7wtZ00a6acvallq9qvPA.otf"}]}], {supportsExplicitInterCodegen: true})